<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="permissionData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching permission data
      </h4>
      <div class="alert-body">
        No permission found with this permission id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'permissions'}"
        >
          Permission List
        </b-link>
        for other permissions.
      </div>
    </b-alert>

    <template v-if="permissionData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <permission-view-permission-info-card :permission-data="permissionData" />
          <permission-view-permission-timeline-card />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <permissions-groups-card
            :permissions="[permissionData]"
            :can-view="permissionData.allowed_content_types.split(',')"
            :single="true"
          />
        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import PermissionViewPermissionInfoCard from './PermissionViewPermissionInfoCard.vue'
import PermissionViewPermissionTimelineCard from './PermissionViewPermissionTimelineCard.vue'
import PermissionsGroupsCard from '../../../layouts/components/PermissionGroupsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    PermissionViewPermissionInfoCard,
    PermissionViewPermissionTimelineCard,
    PermissionsGroupsCard,

  },
  setup() {
    const permissionData = ref(null)

    store.dispatch('app/fetchPermission', { id: router.currentRoute.params.id })
      .then(response => {
        permissionData.value = response.data.results[0]
        if (permissionData.value.allowed_content_types == null) permissionData.value.allowed_content_types = ''
      })
      .catch(error => {
        if (error.response.status === 404) {
          permissionData.value = undefined
        }
      })

    return {
      permissionData,
    }
  },
}
</script>

<style>

</style>
