<template>
  <b-card>

    <b-row>

      <!-- Permission Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Permission Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ permissionData.name }}
              </h4>
              <span class="card-text">{{ permissionData.full_name }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'permissions-edit', params: { id: permissionData.id } }"
                variant="primary"
              >
                Edit
              </b-button>
              <b-button
                id="deleteTooltip" href="#"
                variant="outline-danger"
                class="ml-1"
              >
                Delete
              </b-button>
              <b-tooltip ref="deleteTooltip" target="deleteTooltip" title="Tooltip title" triggers="click">
                <div>Are you sure?</div> <b-link class="font-weight-bolder" @click="closeDeleteTooltip">Cancel</b-link> - <b-link class="text-danger font-weight-bolder" @click="deleteThis">Delete</b-link>
              </b-tooltip>
            </div>
          </div>
        </div>


      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge :variant="(permissionData.enabled) ? 'success' : 'light-secondary'">
                {{ (permissionData.enabled) ? 'Active' : 'Inactive' }}
              </b-badge>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BLink, BButton, BAvatar, BRow, BCol, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import usePermissionsList from '../permissions-list/usePermissionsList'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BBadge,
    BCard,
    BLink,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BTooltip,
  },
  props: {
    permissionData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeDeleteTooltip() {
      this.$refs.deleteTooltip.$emit('close')
    },
  },
  setup() {
    // Use toast
    const toast = useToast()

    const { resolvePermissionRoleVariant } = usePermissionsList()

    const deleteThis = () => {
      let permissionId = router.currentRoute.params.id

      store.dispatch('app/deletePermission', { id: permissionId })
        .then(() => {
          // Redirect to content items list
          router.push({ name: 'permissions' })
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating new user.',
              text: 'ERROR: ' + error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      avatarText,
      deleteThis,
      resolvePermissionRoleVariant,
    }
  },
}
</script>

<style>

</style>
